exports.linkResolver = (doc) => {
  if (doc.type === "project") {
    return `/projects/${doc.uid}`
  }

  if (doc.type === "about_page") {
    return `/about`
  }

  if (doc.type === "contact_page") {
    return `/contact`
  }

  return "/"
}
